<template>
  <div class="flex-1 flex flex-col  max-h-full">
    <sub-header :handleClick="handleClick" :backButton="true" backRedirectionUrl="/check-admin"  :showBtn="checkViewData && checkViewData.is_custom" buttonIcon="edit" buttonText="Edit Check" />
    <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar">
      <span v-if="isLoading" class="flex flex-col min-w-full mt-6 bg-card-bg items-center justify-center rounded-md">
            <Loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                Loading
        </span>
        <dv-table :showSorting="true" v-else :showFilters="true" :isLoading="isLoading" :headers="getColumns" :isCollapsible="true" collapseKey="checkSections" :rows="items">
            <template v-slot:items="{ row }">
                <td class="flex-wrap py-4 px-3 text-sm">
                    <div class="flex items-center gap-2">
                      <div >
                        <div class="font-medium text-gray-900 max-w-xs line-clamp-1">{{row.name}}</div>
                        <p class="text-gray-500 max-w-xs break-words truncate text-xs line-clamp-1"> {{row.description}} </p>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p v-if="row.type !== 'Candidate Consent' "  class="text-gray-500 max-w-xs break-words truncate">{{row.label}}</p>
                    <p v-else>...</p>
                  </td>
                  

                  <!-- <td class="px-3 py-4 text-sm text-gray-500 ">
                      <div class="flex gap-4 items-center" v-if="row.roles.length && getRoleData(row.roles, 'Candidate')">
                          <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Candidate').read" v-tippy content="read " />
                          <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Candidate').write" v-tippy content="write" />
                          <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Candidate').visible" v-tippy content="visible " />                       
                      </div>
                      <span v-else class="text-yellow-600"> permisions not available </span>
                  </td>
                  <td class="px-3 py-4 text-sm text-gray-500 ">
                      <div class="flex gap-4 items-center" v-if="row.roles.length && getRoleData(row.roles, 'Client Admin')">                           
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Client Admin').read" v-tippy content="read " />
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Client Admin').write" v-tippy content="write" />                            
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Client Admin').visible" v-tippy content="visible " />                         
                      </div>
                      <span v-else class="text-yellow-600"> permisions not available </span>
                  </td>

                  <td class="px-3 py-4 text-sm text-gray-500 ">
                      <div class="flex gap-4 items-center" v-if="row.roles.length && getRoleData(row.roles, 'Referee')">
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Referee').read" v-tippy content="read "/>                            
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Referee').write" v-tippy content="write" />                           
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="getRoleData(row.roles, 'Referee').visible" v-tippy content="visible " />                           
                      </div>
                      <span v-else class="text-yellow-600"> permisions not available </span>
                  </td> -->

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="inline-flex rounded-sm  px-2 text-xs font-semibold leading-5 " :class="row.is_custom ? 'bg-green-100 text-green-800' : 'bg-blue-200 text-blue-800' " >{{row.is_custom? 'Custom': 'locked'}}</span>
                  </td>
                  
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                     {{row.type}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <p class="text-gray-500 max-w-xs break-words truncate text-xs line-clamp-1"> {{row.placeholder}} </p>
                  </td>

                  <template v-if="row.roles.length">
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" v-for="rolesData in row.roles" :key="rolesData.id">
                        
                        <div class="flex gap-4 items-center" v-if="rolesData">                   
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="rolesData.role_read" v-tippy content="read " />
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="rolesData.role_write" v-tippy content="write" />
                            <Checkbox :disabled="true" class="checkbox-xs" v-model="rolesData.role_visible" v-tippy content="visible "  />
                        </div>
                        <span v-else class="text-yellow-600 capitalize"> {{ $t('pages.check_admin.permisions_not_available')}} </span>
                    </td>

                  </template>
                  <!-- <td v-else>
                        <span class="text-yellow-600"> permisions not available </span>
                  </td> -->

                  
                  <!-- <td class="py-4 px-2 text-center text-xl font-medium">
                        <font-awesome-icon icon="angle-right" class=" text-brand w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                  </td> -->
            </template>
            <template v-slot:no-data>
              <span class="w-full flex items-center justify-center p-3">{{ $t('pages.email_admin.no_data_found')}}</span>
          </template>
        </dv-table>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import dvTable from  "@/components/devliumComponent/field-table/dv-table"
// import { columns, rows } from '../field-table/fieldTableData'
import Loader from "@shared/loader"
import { fields } from '../field-table/fieldData'
import axios from "@/axios";
import Checkbox from "@/components/checkbox";

export default {
  name: "check-admin",
  components: {
      SubHeader,
      dvTable,
      Loader,
      Checkbox
  },
  props: {},
  data: () => ({
        columns: [
            {"label":this.$t('pages.email_admin.name'),"field":"name", config:{filter: true, type:"search", sort:true}},
            {"label":this.$t('pages.field_table.label'),"field":"label", config:{filter: true, type:"search", sort:true}},
            {"label":this.$t('pages.field_table.source'),"field":"is_custom"},
            {"label":this.$t('pages.field_table.type'),"field":"type", config:{filter: true, type:"select", sort:true}},
            {"label":this.$t('pages.field_table.placeholder'), config:{filter: true, type:"search", sort:true}}
          ],
        items: [],
        fieldData: fields,
        isLoading:false,
        checkViewData: null 
  }),
        computed: {
        getColumns(){
            let all_permission = []
            this.items.forEach(row => row.roles.forEach(role => {
                let permision_data = all_permission?.find(per => per.label === role.title)
                if(!permision_data){
                    all_permission.push({label: role.title, field: role.code, field_type:'role'})
                }
            }))
            all_permission.forEach(per => this.columns.push(per))
            return this.columns
        }
    },
  async mounted() {
    await this.getCheckDetails()
  },
  watch:{
      checkViewData: function(val){
        this.$route.meta.customCrumbs = [] 
        this.$route.meta.customCrumbs = [{name: val?.name }]  
        this.reloadRouter();
      }
  },
  title: 'Checks',
  methods: {
        reloadRouter () {
          // Add a temporary query parameter.
          this.$router.replace({query: {temp: Date.now()}})
          // Remove the temporary query parameter.
          this.$router.replace({query: {temp: undefined}})
        },
        getIcon(field_name){
            return this.fieldData.find(ele => ele.type === field_name).svg || ""
        },
        handleClick(){
            this.$router.push({path:`/check-admin/view/${this.$route.params.id}/setting`});
        },
        
        // Get Check Details

        async getCheckDetails() {
          this.isLoading = true
          let queryId = this.$route.params.id
          this.$route.meta.customCrumbs = []
          this.reloadRouter();
          if(queryId){
            let url = `/tenant-check/${this.$store.getters.getTenantId}/${queryId}`;
            let {data} = await axios.get(url);
            try{
                let checkData = data.data
                this.$route.meta.customCrumbs = [{name: checkData?.name }]   
                this.reloadRouter();
                this.checkViewData = data.data
                let fieldsList = []
                checkData.sections?.map(section => section.fields?.map(field => fieldsList.push(field)))
                this.items = fieldsList
                this.isLoading = false
            }catch(error){
              this.isLoading = false
            }
            this.isLoading = false
          
            // let checkData = rows.find(ele => ele.id == queryId)
            // let checkSectionsfields = []
            // checkData.checkSections.map(el => el.checkFields.map(element => checkSectionsfields.push(element)))
            // return this.items  = checkSectionsfields
        }

        // End Get Check Details
    },
    getRoleData(roles, type){
        let role = roles.find(el => el.title === type )
        if(type){
          let permisons = {'read': role.role_read, 'write': role.role_write, 'visible':  role.role_visible}
          return permisons
        }
        else{
          return 'no permisions found'
        }
    }
  },
};
</script>

<style lang="scss">
  .checkbox:disabled{
    opacity: 0.5 !important;
    border:none !important;
  }
  
</style>