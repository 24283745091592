<template>
    <div class="flex gap-6 flex-col bg-white rounded-md">
        <FormulateForm class="min-h-full flex-1" 
            :class="isLoading ? 'opacity-50 pointer-events-none': ''"
            #default="{ hasErrors }">
            <div class="flex flex-col gap-3 p-3">
                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.client')"
                        v-model="reportData.client_id"
                        @change="fetchPackages()"
                        validation="required"
                        :placeholder="$t('report_templates.create_template_form.selectClient')"
                        type="select"
                        variant="alt"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                        :options="allClients"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.orcaPackage')"
                        v-model="reportData.package_id"
                        validation="required"
                        :placeholder="$t('report_templates.create_template_form.selectPackage')"
                        type="select"
                        variant="alt"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                        :options="allPackages"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.reportStyle')"
                        v-model="reportData.report_style_id"
                        validation="required"
                        :placeholder="$t('report_templates.create_template_form.selectReportStyle')"
                        type="select"
                        variant="alt"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                        :options="allReportStyles"
                    />
                </div>
                
                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.file')"
                        v-model="reportData.template_id"
                        validation="required"
                        :placeholder="$t('report_templates.create_template_form.selectFile')"
                        type="select"
                        variant="alt"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                        :options="allReportFiles"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.custom_logo')"
                        v-model="reportData.has_custom_logo"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start relative">                        
                    <FormulateInput 
                        type="image" 
                        :label="$t('report_templates.create_template_form.custom_logo_files')"
                        name="Custom Logo Image" 
                        :uploader="handleCustomLogo"  
                        :value="custom_logo_files"  
                        v-model="custom_logo_files"
                        @input="handleCustomLogo"
                        class="w-full label-w-33"
                        help="Select a png, jpg, jpeg or gif to upload." 
                        validation="mime:image/jpg,image/png,image/gif,image/jpeg" 
                        :validation-messages="{ mime: 'File must be of type jpg/jpeg gif, png'}" 
                    >
                    </FormulateInput>
                    <div class="rounded-md flex absolute" 
                        style="margin-left: calc(33.33% + 20rem)" v-if="reportData.custom_logo">
                        <img class="formulate-input-upload-area rounded-md h-20 w-20" :src="`${templateBaseUrl}${reportData.custom_logo}`">

                        <div class="mx-2 cursor-pointer"
                            v-if="reportData.custom_logo"
                            @click.stop="removeLogo('custom_logo')" title="Remove logo">
                            <Cross style="fill: #b2b2b2; height: 24px;width: 24px;" />
                        </div>
                    </div>
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.ribbon_neotas_logo')"
                        v-model="reportData.ribbon_neotas_logo"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.different_footer_logo')"
                        v-model="reportData.different_footer_logo"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start relative">
                    <FormulateInput 
                        type="image"  
                        :label="$t('report_templates.create_template_form.footer_custom_logo_files')"
                        name="Footer Custom Logo Image" 
                        :uploader="handleFooterLogo"  
                        :value="footer_custom_logo_files"  
                        v-model="footer_custom_logo_files"
                        @input="handleFooterLogo"
                        class="w-full label-w-33"
                        help="Select a png, jpg, jpeg or gif to upload." 
                        validation="mime:image/jpg,image/png,image/gif,image/jpeg" 
                        :validation-messages="{ mime: 'File must be of type jpg/jpeg gif, png'}" 
                    >
                    </FormulateInput>
                    <div class="rounded-md flex absolute" 
                        style="margin-left: calc(33.33% + 20rem)" v-if="reportData.footer_custom_logo">
                        <img class="formulate-input-upload-area rounded-md h-20 w-20" :src="`${templateBaseUrl}${reportData.footer_custom_logo}`">

                        <div class="mx-2 cursor-pointer"
                            v-if="reportData.footer_custom_logo"
                            @click.stop="removeLogo('footer_custom_logo')" title="Remove logo">
                            <Cross style="fill: #b2b2b2; height: 24px;width: 24px;" />
                        </div>
                    </div>
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.footer_has_text')"
                        v-model="reportData.footer_has_text"
                        type="checkbox"
                        class="label-w--32"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.footer_custom_text')"
                        v-model="reportData.footer_custom_text"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.footer_custom_text_color')"
                        v-model="reportData.footer_custom_text_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>


                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.require_tnc')"
                        v-model="reportData.require_tnc"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.got_question_visible')"
                        v-model="reportData.got_question_visible"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>
                
                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.custom_frontpage_text')"
                        v-model="reportData.custom_frontpage_text"
                        placeholder=""
                        type="textarea"
                        rows="16"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96 overflow-y-auto scroll-bar resize-none'].concat(classes)"
                    />
                </div>
                
                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.custom_lastpage_text')"
                        v-model="reportData.custom_lastpage_text"
                        placeholder=""
                        type="textarea"
                        rows="16"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96 overflow-y-auto scroll-bar resize-none'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.top_ribbon_color')"
                        v-model="reportData.top_ribbon_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.header_text')"
                        v-model="reportData.header_text"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>
                
                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.header_subtext')"
                        v-model="reportData.header_subtext"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.header_color')"
                        v-model="reportData.header_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.header_text_color')"
                        v-model="reportData.header_text_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.footer_color')"
                        v-model="reportData.footer_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.powered_text')"
                        v-model="reportData.powered_text"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.powered_color')"
                        v-model="reportData.powered_color"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_red_pages')"
                        v-model="reportData.show_red_pages"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_amber_pages')"
                        v-model="reportData.show_amber_pages"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_social_profile_pages')"
                        v-model="reportData.show_social_profile_pages"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_data_breach_pages')"
                        v-model="reportData.show_data_breach_pages"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.orca_only')"
                        v-model="reportData.orca_only"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_case_id')"
                        v-model="reportData.show_case_id"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_other_name')"
                        v-model="reportData.show_other_name"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.show_black_page')"
                        v-model="reportData.show_black_page"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.use_flags')"
                        v-model="reportData.use_flags"
                        type="checkbox"
                        :element-class="(context, classes) => ['flex-1'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.default_red')"
                        v-model="reportData.default_red"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.default_green')"
                        v-model="reportData.default_green"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full gap-3 items-start">
                    <FormulateInput
                        :label="$t('report_templates.create_template_form.default_gray')"
                        v-model="reportData.default_gray"
                        placeholder=""
                        type="text"
                        class="w-full label-w-33"
                        :element-class="(context, classes) => ['flex-1 w-96'].concat(classes)"
                    />
                </div>

                <div class="flex w-full">
                    <div class="ml-auto"> 
                        <button
                            class="h-10 w-32 disabled:opacity-50 p-2 text-white rounded-md"
                            :class="!checkPermission('reportTemplates.edit') || hasErrors ? 'bg-gray-200 cursor-not-allowed': 'bg-blue-600 cursor-pointer'"
                            @click="updateTemplate()"
                            :disabled="!checkPermission('reportTemplates.edit') || hasErrors"
                        >
                            {{ $t('report_templates.save_button_text') }}
                        </button>
                    </div>
                </div>
            </div>
        </FormulateForm>


        <span id="template-loader" class="absolute bg-blue-100 px-4 py-2 max-h-20 rounded-lg text-center top-1/2 w-96" style="left: calc(50% - 12rem)" v-if="isLoading">
            <div class="spinner-4" style="margin-left: calc(50% - 15px)"></div>
            <span class="ml-2">{{ $t('report_templates.loader_text') }}</span>
        </span>
    </div>
</template>

<script>
import axios from "@/axios";
import { mapActions, mapGetters } from 'vuex';
import { checkPermission } from "@shared/utils/functions";
import Cross from "@shared/light-ray/assets/svg/cross.svg";

export default {
    name: "check-details",
    components: {
        Cross,
    },
   props: {
    },
    data() {
        return {
            isLoading: false,
            reportData: {
                client_id: null,
                package_id: null,
                has_custom_logo: false,
                custom_logo: "",
                ribbon_neotas_logo: true,
                different_footer_logo: false,
                footer_custom_logo: "",
                footer_has_text: false,
                footer_custom_text: "",
                footer_custom_text_color: "#FFFFFF",
                report_style: null,
                require_tnc: true,
                got_question_visible: true,
                custom_frontpage_text: "",
                custom_lastpage_text: "",
                template_id: "",
                top_ribbon_color: "#161e39",
                header_text: "Social Media Screening Report",
                header_subtext: "",
                header_color: "#e0e0e0",
                header_text_color: "#0d69d5",
                footer_color: "#161e39",
                powered_text: "Powered by Neotas",
                powered_color: "#FFFFFF",
                show_red_pages: false,
                show_amber_pages: false,
                show_social_profile_pages: true,
                show_data_breach_pages: false,
                orca_only: true,
                show_case_id: true,
                show_other_name: false,
                show_black_page: false,
                use_flags: true,
                default_red: "#ff0000",
                default_green: "#008000",
                default_gray: "#e0e0e0",
            },
            allClients: [],
            allPackages: [],
            allReportStyles: [],
            allReportFiles: [],
            selectedTemplateId: "",
            templateBaseUrl:'https://neotas-reports-storage.s3.eu-west-2.amazonaws.com/reports-templates/',
            custom_logo_files: [],
            footer_custom_logo_files: [],
            customLogo: "",
            footerCustomLogo: "",
        }
    },
    computed: {
        ...mapGetters([
        ]),
    },
    methods: {
        ...mapActions([
        ]),

        checkPermission,

        async fetchClients() {
            const tenant_id = this.$store.getters.getTenantId;
            const data = await axios.get(`/tenant/${tenant_id}/client`);
            this.allClients = data.data.map((el) => ({
                ...el,
                label: el.name,
                value: el.id,
            }));
        },

        async fetchPackages() {
            if (this.reportData.client_id) {
                const data = await axios.get(`screening-package/client/${this.reportData.client_id}`);
                this.allPackages = data.data.map((el) => ({
                    label: el.package_name,
                    value: el.package_id,
                })) || [];
            } else this.allPackages = [];
        },

        async fetchReportFiles() {
            const {data} = await axios.get(`/tenant-templates`);
            this.allReportFiles = data.data.map((el) => ({
                ...el,
                label: el.name,
                value: el.id,
            })) || [];
        },

        async fetchReportStyles() {
            const {data} = await axios.get(`/tenant-templates/style`);
            this.allReportStyles = data.data.map((el) => ({
                ...el,
                label: el.name,
                value: el.id,
            })) || [];
        },

        async getTemplateDetails() {
            const {data} = await axios.get(`/tenant-templates/assigned-templates/${this.selectedTemplateId}`);
            this.reportData = data.data;
        },

        async updateTemplate() {
            let data;
            const payload = {
                ...this.reportData,
                custom_logo: this.customLogo || this.reportData.custom_logo,
                footer_custom_logo: this.footerCustomLogo || this.reportData.footer_custom_logo,
            }
            if (this.selectedTemplateId)
                data = await axios.put(`/tenant-templates/assigned-templates/${this.selectedTemplateId}`, payload);
            else 
                data = await axios.post(`/tenant-templates/assigned-templates/create`, payload);

            if (data.status === 200  && data.data) {
                this.$toast.success(this.$t('report_templates.template_update_success'));
                await this.getTemplateDetails();
                await this.fetchPackages();
            }
            // this.reportData = data.data;
        },

        async handleCustomLogo() {
            let file = this.getOptions('custom_logo')[0].file;
            const formData = new FormData();
            formData.append("file",file);
            let case_id = this.getNeoCaseId;
            const url = '/service/reports/images'
            let response = await axios.post(url, formData, {
                headers: {
                    'x-case-id': case_id
                }
            });
            this.customLogo = response.data.file;
            // this.reportData.custom_logo =  response.data.file;
        },

        async handleFooterLogo() {
            let file = this.getOptions('footer_custom_logo')[0].file;
            const formData = new FormData();
            formData.append("file",file);
            const url = '/service/reports/images'
            let response = await axios.post(url, formData);
            this.footerCustomLogo =  response.data.file;
            // this.reportData.footer_custom_logo =  response.data.file;
        },

        removeLogo(field) {
            this.reportData[field] = "";
        },

        getOptions(field) {
            return this[`${field}_files`].files
        }
    },

    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchClients();
            await this.fetchReportFiles();
            await this.fetchReportStyles();

            this.selectedTemplateId = this.$route.params.id;
            if (this.selectedTemplateId) {
                await this.getTemplateDetails();
                await this.fetchPackages();
            }
            this.isLoading = false;
        } catch {
            this.isLoading = false;
        }
    },
};
</script>

<style lang="scss">
@import '@/assets/mixins/mixins.scss';
.scroll-bar {
  @include scrollBar;
}

.formulate-input-wrapper {
    display: flex;
    align-items: flex-start;
    .formulate-input-element--checkbox {
        margin-right: 16px;
    }
    .formulate-input {
        .formulate-input-element {
            .formulate-input-element--image {
                width: 20rem;
            }
        }
    }
    .formulate-input-upload-area {
        border: 1px solid #cecece;
        border-radius: 8px;
        display: flex;
        padding: 0px!important;
        height: 80px!important;
        justify-content: space-between!important;
        align-items: center!important;
        input {
            position: unset!important;
            width: calc(100% - 100px)!important;
        }
        // img {
        //     height: 80px!important;
        //     width: 80px!important;
        // }
    }
}
// .formulate-input[data-classification="file"],
// .formulate-input-upload-area[data-has-files] {
//     input {
//         display: block!important;
//    }
// }

.formulate-input-help {
    margin-left: 33.33%
}

.formulate-files {
    // display: none;
    width: 100%;
}
.label-w-33 {
    label {
        width: 33.33%
    }
}
.label-w--32 {
    label {
        width: calc(100% - 32px);
    }
}


.spinner-4 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 1px;
    margin-right: 1rem;
    background: conic-gradient(#0000 10%, #0d69d5) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 calc(100% - 8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: s4 1s infinite steps(10);
}
@keyframes s4 {
    to {
        transform: rotate(1turn);
    }
}
</style>
;